import { useState, useEffect } from 'react';
import useEndpoint from '../hooks/endpoint.hook';

import {
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Grid,
  Box,
  Typography,
  Paper
} from '@mui/material';

import AlertMessageComponent from '../components/AlertMessage';
import { useGarageLocation } from '../components/Location';

const Reeta = () => {
  const endpoint = useEndpoint();
  const { garageLocation } = useGarageLocation()!;

  interface Vehicle {
    id: number;
    make: string;
    model: string
    gen: string
  }

  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const [vehicleMappings, setVehicleMappings] = useState<Vehicle[]>([]);
  const [reetaIds, setReetaIds] = useState([]);
  const [reetaId, setReetaId] = useState('');
  const [carType, setCarType] = useState('');

  const [stallNumbers, setStallNumbers] = useState<number[]>([]);
  const [stallNumber, setStallNumber] = useState('');

  const [addReetaParams, setAddReetaParams] = useState<any[]>([]); // populate with [{reeta_id: '', ip: ''},{reeta_id: '', ip: ''}...]

  const [addReetaId, setAddReetaId] = useState('');
  const [addReetaIP, setAddReetaIP] = useState('');

  useEffect(() => {
    if (addReetaId !== '') {
      const reeta_ip = addReetaParams.find(param => param.reeta_id === addReetaId)?.reeta_ip;
      console.log (`reeta_ip: ${reeta_ip}`);
      setAddReetaIP(reeta_ip);
    }
  }, [addReetaId, addReetaParams]);

  const handleReetaCommand = (cmd: 'plug' | 'unplug' | 'open_chargeport' | 'close_chargeport' | 'scan_chargeport') => {
    // const car_type_number = vehicleMappings.find((vehicle: Vehicle) => vehicle.car_type === carType)?.id;
    endpoint?.post("reeta/api/send_command", {
      params: {
        location: garageLocation,
        reeta_id: reetaId,
        car_type: carType, // make sure to use the id number of the car, not plaintext name
        stall_num: stallNumber,
        cmd: cmd
      }
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(`${addReetaId} ${cmd} command sent successfully.`);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetReetaStatus = () => {
    endpoint?.get('reeta/api/get_reeta_status',
      {
        params: {
          location: garageLocation,
          reeta_id: reetaId
        },
      }
    ).then(response => {
      setIsErrorMessage(false);
      setMessage(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }
  
  const handleConnectReeta = () => {
    endpoint?.post('reeta/api/connect_reeta', {
      params: {
        location: garageLocation,
        ip: addReetaIP,
        reeta_id: addReetaId
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(`REETA ${addReetaId} connected successfully.`);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleDisconnectReeta = () => {
    endpoint?.post('reeta/api/disconnect_reeta', {
      params: {
        location: garageLocation,
        reeta_id: reetaId
      },
    }).then(response => {
      setIsErrorMessage(false);
      setMessage(`REETA ${addReetaId} disconnected successfully.`);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  useEffect(() => {
    if (reetaId !== '') {
      endpoint?.get('sotefin/api/get_ev_stalls',{
        params: {
          location: garageLocation
        }
      }).then(response => {
        const ev_stalls = response.data
        const filtered_stalls = ev_stalls.filter((stalls: any) => stalls.reeta_id === reetaId);
        const stallNumbers = filtered_stalls.flatMap((stall: any) => stall.stall_nums);
        console.log(`filtered stalls: ${JSON.stringify(filtered_stalls)}`);
        setStallNumbers(stallNumbers);
      }).catch(error => {
        console.error('There was an error!', error);
        setIsErrorMessage(true);
        setMessage(error.response ? error.response.data : error.message);
      });
    }
  }, [garageLocation, endpoint, reetaId]);

  useEffect(() => {
    // for reetas to connect
    endpoint?.get('sotefin/api/get_ev_stalls',{
      params: {
        location: garageLocation
      }
    }).then(response => {
      let reeta_params: any = [];
      const ev_stalls = response.data
      for (let i = 0; i < ev_stalls.length; i++) {
        let reeta = {
          reeta_id: ev_stalls[i].reeta_id,
          reeta_ip: ev_stalls[i].reeta_ip
        }
        if (!reeta_params.find((r: any) => r.reeta_id === reeta.reeta_id && r.reeta_ip === reeta.reeta_ip)) {
          reeta_params.push(reeta);
        }
      }
      console.log(`reeta_params: ${JSON.stringify(reeta_params)}`);
      setAddReetaParams(reeta_params);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
    // console.log(`addReetaParams: ${JSON.stringify(addReetaParams)}`);
  }, [garageLocation, endpoint]);

  useEffect(() => {
    // for connected reetas
    endpoint?.get('reeta/api/get_reeta_ids',{
      params: {
        location: garageLocation
      }
    }).then(response => {
      const reeta_ids = response.data.map((reeta: any) => reeta.reeta_id);
      setReetaIds(reeta_ids);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [garageLocation, endpoint]); // add handleconnectreeta to dependencies?

  useEffect(() => {
    endpoint?.get('reeta/api/get_vehicle_mapping').then(response => {
      setVehicleMappings(response.data);
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  }, [endpoint]); // add handleconnectreeta to dependencies?

  return (
    <Box sx={{ flexGrow: 1, padding: 3 }}>
      <Typography variant="h4" align="center" gutterBottom>
        REETA Control
      </Typography>

      <Paper elevation={2} sx={{ marginY: 2 }}>
        <Typography variant="h5" align="center">
          Send REETA Command
        </Typography>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
          <Grid item xs={3} sx={{ minWidth: 160 }}>
            <Grid item xs={2} sx={{ minWidth: 160, margin: '1em' }}>
              <FormControl
              fullWidth={true}
              >
                <InputLabel>REETA ID</InputLabel>
                <Select
                  value={reetaId}
                  onChange={(event) => setReetaId(event.target.value as string)}
                >
                  {reetaIds.map(reeta_id => (
                    <MenuItem key={reeta_id} value={`${reeta_id}`}>{`${reeta_id}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 160, margin: '1em' }}>
              <FormControl
              fullWidth={true}
              >
                <InputLabel>Car Type</InputLabel>
                <Select
                  value={carType}
                  onChange={(event) => {
                    console.log(`setting car type to: ${event.target.value}`);
                    setCarType(event.target.value as string);
                  }}
                >
                  {vehicleMappings.map((mapping: any) => (
                    <MenuItem key={mapping.id} value={`${mapping.id}`}>{mapping.make}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 160, margin: '1em' }}>
              <FormControl
              fullWidth={true}
              >
                <InputLabel>Stall Number</InputLabel>
                <Select
                  value={stallNumber}
                  onChange={(event) => setStallNumber(event.target.value as string)}
                >
                  {stallNumbers.map((stall: any) => (
                    <MenuItem key={stall} value={`${stall}`}>{stall}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={9} sx={{ minWidth: 160 }}>
            <Button
              variant="contained"
              onClick={() => handleReetaCommand('plug')}
              sx={{ margin: '1em' }}
            >
              Send Plug Command
            </Button>
            <Button
              variant="contained"
              onClick={() => handleReetaCommand('unplug')}
              sx={{ margin: '1em' }}
            >
              Send Unplug Command
            </Button>
            <Button
              variant="contained"
              onClick={() => handleReetaCommand('open_chargeport')}
              sx={{ margin: '1em' }}
            >
              Send Open Chargeport Command
            </Button>
            <Button
              variant="contained"
              onClick={() => handleReetaCommand('close_chargeport')}
              sx={{ margin: 2 }}
            >
              Send Close Chargeport Command
            </Button>
            <Button
              variant="contained"
              onClick={() => handleReetaCommand('scan_chargeport')}
              sx={{ margin: '1em' }}
            >
              Send Scan Chargeport Command
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ marginY: 2, paddingX: 2 }}>
        <Typography variant="h5" align="center">
          Get REETA Status
        </Typography>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <FormControl
            fullWidth={true}
            >
              <InputLabel>REETA ID</InputLabel>
                <Select
                  value={reetaId}
                  onChange={(event) => setReetaId(event.target.value as string)}
                >
                  {reetaIds.map(reeta_id => (
                    <MenuItem key={reeta_id} value={`${reeta_id}`}>{`${reeta_id}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 120 }}>
            <Button
              variant="contained"
              onClick={handleGetReetaStatus}
              sx={{ marginTop: 2 }}
            >
              Get REETA Status
            </Button>
          </Grid>
        </Grid>
      </Paper>
      
      <Paper elevation={2} sx={{ marginY: 2, paddingX: 2 }}>
        <Typography variant="h5" align="center">
        Connect REETA (beta)
        </Typography>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <Grid item xs={2} sx={{ minWidth: 160 }}>
              <FormControl
              fullWidth={true}
              >
                <InputLabel>REETA ID</InputLabel>
                <Select
                  value={addReetaId}
                  onChange={(event) => {
                    setAddReetaId(event.target.value as string)
                  }}
                >
                  {addReetaParams.map(reeta => (
                    <MenuItem key={reeta.reeta_id} value={`${reeta.reeta_id}`}>{`${reeta.reeta_id}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <Button
              variant="contained"
              onClick={handleConnectReeta}
              // make sure that the reeta ip is pulled from the reeta id
              sx={{ marginTop: 2 }}
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ marginY: 2, paddingX: 2 }}>
        <Typography variant="h5" align="center">
          Connect REETA (will be deprecated)
        </Typography>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <FormControl
              fullWidth={true}
            >
              <InputLabel>REETA IP</InputLabel>
              <Input
                id="reeta_ip"
                value={addReetaIP}
                onChange={(e) => setAddReetaIP(e.target.value)} // Update reetaIp state on change
              />
            </FormControl>
            <FormControl
              fullWidth={true}
            >
              <InputLabel>REETA ID</InputLabel>
              <Input
                id="reeta_id"
                value={addReetaId}
                onChange={(e) => setAddReetaId(e.target.value)} // Update reetaId state on change
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <Button
              variant="contained"
              onClick={handleConnectReeta}
              sx={{ marginTop: 2 }}
            >
              Connect
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ marginY: 2, paddingX: 2 }}>
        <Typography variant="h5" align="center">
          Disconnect REETA
        </Typography>
        <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
          <Grid item xs={6} sx={{ minWidth: 160 }}>
            <FormControl
            fullWidth={true}
            >
              <InputLabel>REETA ID</InputLabel>
                <Select
                  value={reetaId}
                  onChange={(event) => setReetaId(event.target.value as string)}
                >
                  {reetaIds.map(reeta_id => (
                    <MenuItem key={reeta_id} value={`${reeta_id}`}>{`${reeta_id}`}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sx={{ minWidth: 120 }}>
            <Button
              variant="contained"
              onClick={handleDisconnectReeta}
              sx={{ marginTop: 2 }}
            >
              Disconnect
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <AlertMessageComponent message={message} isErrorMessage={isErrorMessage} />
    </Box>
  );
};

export default Reeta;