import React, { useState } from 'react';
import { useEndpoint } from '../hooks/endpoint.hook';

import {
  FormControl,
  InputLabel,
  Input,
  Select,
  MenuItem,
  Button,
  Grid,
  Card,
  Box,
  Typography,
  CardContent
} from '@mui/material';

import AlertMessageComponent from '../components/AlertMessage';
import { useGarageLocation } from '../components/Location';
import { useCarNumber } from '../components/Smartcars';

const Smartcar = () => {
  const { garageLocation } = useGarageLocation()!;
  const { carNumber, carNumbers, carNumberSelected, setTheCarNumber } = useCarNumber()!;
  const endpoint = useEndpoint();

  const [message, setMessage] = useState('');
  const [isErrorMessage, setIsErrorMessage] = useState(false);

  const [ chargeLimit, setChargeLimit ] = useState(0)
  const [isInvalidChargeLimit, setIsInvalidChargeLimit] = useState(false);

  console.log(`carNumber: ${carNumber}, carNumbers: ${carNumbers}, carNumberSelected: ${carNumberSelected}`);

  const handleGetVehicleInfo = () => {
    endpoint?.get('smartcar/api/get_vehicle_info', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

   const handleChargeLimitChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const pattern = /^(50|[5-9][0-9]|100)$/;

    setChargeLimit(Number.parseFloat(value) || 0);
    if (pattern.test(value)) {
      setIsInvalidChargeLimit(false);
    } else if (value === '') {
      setIsInvalidChargeLimit(false);
    } else {
      setIsInvalidChargeLimit(true);
    }
  };

  const handleSetChargeLimit = () => {
    endpoint?.post('smartcar/api/set_charge_limit', {
      params: { card_num: carNumber, location: garageLocation, limit: chargeLimit },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetChargeLimit = () => {
    endpoint?.get('smartcar/api/get_charge_limit', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetBatteryStatus = () => {
    endpoint?.get('smartcar/api/get_battery_status', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetChargeStatus = () => {
    endpoint?.get('smartcar/api/get_charge_status', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetTeslaChargeStatus = () => {
    endpoint?.get('smartcar/api/get_tesla_charge_status', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleGetTeslaExtendedInfo = () => {
    endpoint?.get('smartcar/api/get_tesla_extended_info', {
      params: { card_num: carNumber, location: garageLocation },
      withCredentials: true
    })
    .then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleSendTeslaCommand = (command: string) => {
    endpoint?.post('smartcar/api/send_tesla_command', {
      params: {
        action: command,
        card_num: carNumber,
        location: garageLocation
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  const handleSendCommand = (command: string) => {
    endpoint?.post('smartcar/api/send_command', {
      params: {
        action: command,
        card_num: carNumber,
        location: garageLocation
      },
      withCredentials: true
    }).then(response => {
      setIsErrorMessage(false);
      if (typeof response.data === "string") {
        setMessage(response.data);
      } else {
        setMessage(response.data);
      }
    }).catch(error => {
      console.error('There was an error!', error);
      setIsErrorMessage(true);
      setMessage(error.response ? error.response.data : error.message);
    });
  };

  return (
    <Box>
      <Typography variant="h4" align="center" gutterBottom>
        Smartcar Control
      </Typography>
      <Card>
        <CardContent>
          <FormControl fullWidth>
            <InputLabel>Car</InputLabel>
            <Select
              value={carNumber}
              onChange={(event) => setTheCarNumber(event.target.value as string)}
            >
              {carNumbers.map(car => (
                <MenuItem key={car} value={car}>{car}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={() => handleSendCommand('unlockCar')} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Unlock Car
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetVehicleInfo} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Vehicle Info
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetChargeLimit} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Charge Limit
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetBatteryStatus} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Battery Status
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetChargeStatus} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Charge Status
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <FormControl error={isInvalidChargeLimit}>
                <InputLabel><b>Charge Limit</b> <br/>*between 50 and 100</InputLabel>
                <Input
                  id="charge_limit"
                  value={chargeLimit}
                  onChange={handleChargeLimitChange}
                  inputProps={{
                    pattern: "^(50|[5-9][0-9]|100)$",
                  }}
                />
              </FormControl>
              <Button 
                variant="contained" 
                onClick={handleSetChargeLimit} 
                disabled={!carNumberSelected || isInvalidChargeLimit || !chargeLimit}
                sx={{ marginTop: 2 }}
              >
                Set Charge Limit
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <hr></hr>
      <Card>
        <CardContent>
          <Grid container spacing={2} justifyContent="space-evenly" sx={{ paddingBottom: 2 }}>
            <Typography variant="h5" align="center" gutterBottom>
              Tesla Specific Smartcar Control
            </Typography>
            <Grid item xs={2} sx={{ minWidth: 240 }}>
              <Button 
                variant="contained" 
                onClick={() => handleSendTeslaCommand("openChargePort")} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Open Charge Port / Release Charger
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 240 }}>
              <Button 
                variant="contained" 
                onClick={() => handleSendTeslaCommand("closeChargePort")} 
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Close Charge Port
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetTeslaChargeStatus}
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Charge Status
              </Button>
            </Grid>
            <Grid item xs={2} sx={{ minWidth: 180 }}>
              <Button 
                variant="contained" 
                onClick={handleGetTeslaExtendedInfo}
                disabled={!carNumberSelected}
                sx={{ marginTop: 2 }}
              >
                Get Tesla Info
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AlertMessageComponent message={message} isErrorMessage={isErrorMessage} />
    </Box>
  )
};

export default Smartcar;